<template>
    <div class="banner">
      <img src="@/assets/img/ziper_Mochila_do_Chef.png" alt="Zíper" class="zipper">
      <img src="@/assets/img/Mochil_do_Chef_logo_white.png" alt="Logo" class="logo">
    </div>
  </template>
  
  <script>
  export default {
    name: 'BannerPage'
  }
  </script>
  
  <style scoped>
  .banner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Cor de fundo do banner */
    position: relative;
  }
  
  .zipper {
    width: 100%;
    height: auto;
    position: absolute;
    top: -200px; /* Move o zíper mais para cima */
  }
  
  .logo {
    width: 50%;
    position: absolute;
    bottom: 25%; /* Aumente o espaço entre o zíper e o logo */
  }
  </style>
  