<template>
  <div class="container" @click="closeDropdowns">
    <BannerPage />
    <div class="register-container">
      <h1>Gerenciar Ferramentas</h1>

      <!-- Formulário para listar ferramentas -->
      <form class="list-form" @submit.prevent>
        <div class="form-card">
          <div class="custom-select" @click.stop="toggleToolDropdown">
            <div class="selected-option">
              <i class="fa fa-list"></i>
              <span>{{ selectedTool ? selectedTool.name : 'Selecione uma Ferramenta' }}</span>
            </div>
            <div v-show="toolDropdownOpen" class="options">
              <div
                v-for="tool in tools"
                :key="tool.id"
                class="option"
                @click="selectTool(tool)"
              >
                <span class="icon-label">{{ tool.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="selectedTool && selectedTool.id !== 0">
            <input
              type="text"
              v-model="selectedTool.name"
              placeholder="Nome da Ferramenta"
              required
            />
            <select v-model="selectedCategory" required>
              <option value="" disabled>Selecione uma Categoria</option>
              <option
                v-for="category in filteredCategories"
                :key="category.id"
                :value="category"
              >
                {{ category.name }}
              </option>
            </select>
            <label for="fileInput">Imagem da Ferramenta:</label>
            <input type="file" ref="fileInput" @change="handleFileUpload" />
            <label for="contentFileInput">Conteúdo da Ferramenta:</label>
            <input
              type="file"
              ref="contentFileInput"
              @change="handleContentFileUpload"
            />
            <button type="button" @click="updateTool" :disabled="loading">
              <span v-if="!loading">Atualizar</span>
              <div v-else class="spinner"></div>
            </button>
            <button
              type="button"
              @click="confirmDeleteTool"
              class="delete-button"
              :disabled="loading"
            >
              <span v-if="!loading">Excluir</span>
              <div v-else class="spinner"></div>
            </button>
          </div>
        </div>
      </form>

      <h1 v-if="selectedTool === null || selectedTool.id === 0">Cadastrar Ferramenta</h1>

      <!-- Formulário para cadastrar nova ferramenta -->
      <form @submit.prevent="registerTool" class="register-form" @click="closeDropdowns" v-if="selectedTool === null || selectedTool.id === 0">
        <div class="form-card">
          <input
            type="text"
            v-model="name"
            placeholder="Nome da Ferramenta"
            required
          />
          <select v-model="selectedCategory" required>
            <option value="" disabled>Selecione uma Categoria</option>
            <option
              v-for="category in filteredCategories"
              :key="category.id"
              :value="category"
            >
              {{ category.name }}
            </option>
          </select>
          <label for="fileInput">Imagem da Ferramenta:</label>
          <input type="file" ref="fileInput" @change="handleFileUpload" />
          <label for="contentFileInput">Conteúdo da Ferramenta:</label>
          <input
            type="file"
            ref="contentFileInput"
            @change="handleContentFileUpload"
          />
          <button type="submit" :disabled="loading">
            <span v-if="!loading">Cadastrar</span>
            <div v-else class="spinner"></div>
          </button>
          <button type="button" @click="goBack" class="back-button">
            VOLTAR
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Swal from "sweetalert2"; // Importando SweetAlert2
import router from "@/router"; // Importar o router
import BannerPage from "@/components/BannerPage"; // Importando o componente Banner

export default {
  name: "RegisterTool",
  components: {
    BannerPage,
  },
  data() {
    return {
      name: "",
      selectedTool: { id: 0, name: "Selecione uma Ferramenta" },
      selectedCategory: "",
      categorySearch: "",
      loading: false,
      categories: [], // Armazenar as categorias
      filteredCategories: [], // Categorias filtradas
      tools: [{ id: 0, name: "Selecione uma Ferramenta" }], // Ferramenta padrão
      imageFile: null, // Armazenar o arquivo de imagem
      contentFile: null, // Armazenar o arquivo de conteúdo
      toolDropdownOpen: false,
    };
  },
  async created() {
    await this.fetchCategories();
    await this.fetchTools();
    document.addEventListener('click', this.closeDropdowns);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdowns);
  },
  methods: {
    filterCategories() {
      const search = this.categorySearch.toLowerCase();
      this.filteredCategories = this.categories.filter((category) =>
        category.name.toLowerCase().includes(search)
      );
    },
    toggleToolDropdown() {
      this.toolDropdownOpen = !this.toolDropdownOpen;
    },
    closeDropdowns() {
      this.toolDropdownOpen = false;
    },
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
    },
    handleContentFileUpload(event) {
      this.contentFile = event.target.files[0];
    },
    selectTool(tool) {
      this.selectedTool = { ...tool };
      this.selectedCategory = this.categories.find(
        (category) => category.id === tool.category_id
      );
      this.toolDropdownOpen = false;
    },
    async registerTool() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("category_id", this.selectedCategory.id);
        if (this.imageFile) {
          formData.append("image", this.imageFile);
        }
        if (this.contentFile) {
          formData.append("content", this.contentFile);
        }
        await api.createTool(formData);
        this.showSuccessModal("Ferramenta cadastrada com sucesso.");
        this.resetForm(); // Limpar o formulário após cadastro
        await this.fetchTools();
      } catch (error) {
        console.error("Erro ao cadastrar ferramenta:", error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    async updateTool() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("name", this.selectedTool.name);
        formData.append("category_id", this.selectedCategory.id);
        if (this.imageFile) {
          formData.append("image", this.imageFile);
        }
        if (this.contentFile) {
          formData.append("content", this.contentFile);
        }
        await api.updateTool(this.selectedTool.id, formData);
        this.showSuccessModal("Ferramenta atualizada com sucesso.");
        this.resetForm();
        await this.fetchTools();
      } catch (error) {
        console.error("Erro ao atualizar ferramenta:", error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteTool() {
      Swal.fire({
        title: "Atenção!",
        text: "Esta ação irá excluir a ferramenta. Este processo é irreversível. Deseja continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTool();
        }
      });
    },
    async deleteTool() {
      this.loading = true;
      try {
        await api.deleteTool(this.selectedTool.id);
        this.showSuccessModal("Ferramenta excluída com sucesso.");
        this.resetForm();
        await this.fetchTools();
      } catch (error) {
        console.error("Erro ao excluir ferramenta:", error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    async fetchTools() {
      try {
        const response = await api.getTools(); // Buscar ferramentas da API
        this.tools = [{ id: 0, name: "Selecione uma Ferramenta" }, ...response.data];
      } catch (error) {
        console.error("Erro ao buscar ferramentas:", error);
      }
    },
    async fetchCategories() {
      try {
        const response = await api.getCategories(); // Buscar categorias da API
        this.categories = response.data;
        this.filteredCategories = this.categories; // Inicialmente todas as categorias são exibidas
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    },
    showSuccessModal(message) {
      Swal.fire({
        title: "Sucesso!",
        text: message,
        icon: "success",
        confirmButtonText: "OK",
      });
    },
    showErrorModal() {
      Swal.fire({
        title: "Erro!",
        text: "Houve um erro ao processar a solicitação.",
        icon: "error",
        confirmButtonText: "OK",
      });
    },
    goBack() {
      router.back();
    },
    resetForm() {
      this.name = "";
      this.selectedTool = { id: 0, name: "Selecione uma Ferramenta" };
      this.selectedCategory = "";
      this.imageFile = null;
      this.contentFile = null;
      if (this.$refs.fileInput) this.$refs.fileInput.value = "";
      if (this.$refs.contentFileInput) this.$refs.contentFileInput.value = "";
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
}

.banner {
  flex: 1;
  background-color: #000; /* Cor de fundo preta */
}

.register-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #fff; /* Fundo branco para o formulário */
}

h1 {
  margin-bottom: 20px;
  text-align: center;
  color: #000;
}

.register-form,
.list-form {
  width: 100%;
  max-width: 400px; /* Aumentar a largura máxima */
}

.form-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adicionar sombra externa */
  background-color: #f9f9f9;
  margin-bottom: 20px; /* Adicionar espaçamento entre os formulários */
}

label {
  margin: 10px 20px 5px; /* Ajustar o espaçamento */
  font-weight: bold;
}

input,
select,
button {
  width: calc(100% - 40px); /* Ajustar a largura dos campos */
  padding: 10px;
  margin: 10px 20px; /* Ajustar o espaçamento */
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

select {
  cursor: pointer;
}

button {
  background-color: #000;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[disabled] {
  cursor: not-allowed;
  background-color: #666;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
}

.back-button {
  background-color: #ccc;
  color: #000;
}

.back-button:hover {
  background-color: #aaa;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

.custom-select {
  position: relative;
  cursor: pointer;
}

.selected-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.selected-option i {
  margin-right: 10px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
</style>
