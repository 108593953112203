import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.mochiladochef.com/api', // Altere para o URL da sua API
});

// Interceptor para adicionar o token a todas as requisições
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  async login(credentials) {
    const response = await api.post('/login', credentials);
    localStorage.setItem('token', response.data.access_token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    return response;
  },
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },
  getCategories() {
    return api.get('/categories');
  },
  getCategoryTools(categoryId) {
    return api.get(`/categories/${categoryId}/tools`);
  },
  async register(data) {
    return api.post('/users', data);
  },
  createCategory(data) {
    return api.post('/categories', data);
  },
  updateCategory(id, data) {
    return api.put(`/categories/${id}`, data);
  },
  deleteCategory(id) {
    return api.delete(`/categories/${id}`);
  },
  createTool(data) {
    return api.post('/tools', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  updateTool(id, data) {
    return api.put(`/tools/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  deleteTool(id) {
    return api.delete(`/tools/${id}`);
  },
  getTools() {
    return api.get('/tools');
  }
};
