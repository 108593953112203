<template>
  <div class="content">
    <h2>Novas ferramentas toda semana <span class="light-text">sem você pagar um centavo a mais.</span></h2>
    <div class="tools">
      <div v-for="tool in tools" :key="tool.id" class="tool">
        <a :href="tool.content_path" download>
          <img :src="tool.image_path ? tool.image_path : defaultImage" :alt="tool.name" />
        </a>
        <a :href="tool.content_path" download>
          <p>{{ tool.name }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import defaultImage from '@/assets/img/default_tool.webp';

export default {
  name: 'DashboardContent',
  props: {
    selectedCategory: String // Recebe a categoria selecionada como prop
  },
  data() {
    return {
      tools: [],
      defaultImage: defaultImage
    };
  },
  watch: {
    selectedCategory: {
      immediate: true,
      handler(newCategory) {
        this.fetchTools(newCategory);
      }
    }
  },
  methods: {
    async fetchTools(categoryId) {
      try {
        let response;
        if (categoryId === 'All') {
          response = await api.getTools();
        } else {
          response = await api.getCategoryTools(categoryId);
        }
        this.tools = response.data;
      } catch (error) {
        console.error('Erro ao buscar ferramentas:', error);
      }
    }
  }
};
</script>

<style scoped>
.content {
  padding: 20px;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

h2 .light-text {
  font-weight: normal;
}

.tools {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tool {
  width: 150px;
  margin: 10px;
  text-align: center;
}

.tool img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tool p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.tool a {
  text-decoration: none;
  color: inherit;
}
/* Adicionando estilos para telas menores */
@media (max-width: 768px) {
  .tools {
    justify-content: center; /* Continua centralizado em telas menores */
  }
}
</style>
