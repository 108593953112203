<template>
  <div class="dashboard" @click="handleClickOutside">
    <!-- Botão de abrir sidebar (somente em telas menores) -->
    <button class="hamburger-btn" @click.stop="toggleSidebar" v-if="!sidebarOpen && isMobile">
      <i class="fas fa-bars"></i>
    </button>

    <!-- Botão de fechar sidebar (somente em telas menores) -->
    <button class="close-btn" @click.stop="toggleSidebar" v-if="sidebarOpen && isMobile">
      <i class="fas fa-times"></i>
    </button>

    <!-- Sidebar -->
    <div class="sidebar-container" :class="{ open: sidebarOpen }" @click.stop>
      <SidebarMenu @categorySelected="updateSelectedCategory" />
    </div>

    <!-- Conteúdo principal -->
    <div class="main-content">
      <HeaderBar />
      <DashboardContent :selectedCategory="selectedCategory" />
    </div>
  </div>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu.vue';
import HeaderBar from '@/components/HeaderBar.vue';
import DashboardContent from '@/components/DashboardContent.vue';

export default {
  name: 'DashboardView',
  components: {
    SidebarMenu,
    HeaderBar,
    DashboardContent
  },
  data() {
    return {
      selectedCategory: 'All',
      sidebarOpen: false, // Estado para controlar a visibilidade do sidebar
      isMobile: window.innerWidth <= 768 // Detecta se é mobile
    };
  },
  methods: {
    updateSelectedCategory(category) {
      this.selectedCategory = category;
      if (this.isMobile) {
        this.sidebarOpen = false; // Fecha o sidebar ao selecionar uma categoria em mobile
      }
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen; // Alterna entre aberto e fechado
    },
    handleClickOutside() {
      if (this.sidebarOpen && this.isMobile) {
        this.sidebarOpen = false; // Fecha o sidebar ao clicar fora apenas em mobile
      }
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Verifica se é mobile ao redimensionar a tela
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize); // Escuta o redimensionamento da tela
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize); // Remove o listener ao destruir o componente
  }
};
</script>

<style scoped>
.dashboard {
  display: flex;
  height: 100vh; /* Garante que a altura total seja 100% da altura da viewport */
  overflow: hidden; /* Evita a rolagem horizontal */
}

.sidebar-container {
  width: 300px; /* Define a largura do sidebar */
  height: 100vh; /* Garante que a altura do sidebar seja 100% da altura da viewport */
  background-color: #f8f9fa; /* Cor de fundo opcional para o sidebar */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transform: translateX(-100%); /* Sidebar fora da tela inicialmente em mobile */
  transition: transform 0.3s ease;
}

/* Quando o sidebar estiver aberto em mobile */
.sidebar-container.open {
  transform: translateX(0); /* Sidebar visível quando aberto */
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow-y: auto; /* Adiciona rolagem vertical ao conteúdo principal */
}

/* Botão de abrir e fechar (hamburger e close) */
.hamburger-btn,
.close-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 2000;
}

/* Sidebar fixo em telas maiores */
@media (min-width: 769px) {
  .sidebar-container {
    transform: translateX(0); /* Sidebar sempre visível em telas maiores */
    position: relative; /* Sidebar não será fixo */
  }

  /* Esconde os botões de abrir e fechar em telas grandes */
  .hamburger-btn,
  .close-btn {
    display: none;
  }

  .main-content {
    margin-left: 100px; /* Deixa espaço para o sidebar fixo em telas maiores */
  }
}

/* Estilização das barras de rolagem */
.sidebar-container::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
}

.sidebar-container:hover::-webkit-scrollbar {
  opacity: 1;
}

.sidebar-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.sidebar-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.sidebar-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.main-content::-webkit-scrollbar {
  width: 10px;
}

.main-content::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.main-content::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

.main-content::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
</style>
