<template>
    <div class="container">
      <div class="banner">
      </div>
      <div class="login-container">
        <h1>Bem vindo(a)</h1>
        <p class="message">Criar uma conta</p>
        <form @submit.prevent="register" class="login-form">
          <input type="text" v-model="name" placeholder="Nome" required>
          <input type="email" v-model="email" placeholder="E-mail" required>
          <input type="password" v-model="password" placeholder="Senha" required>
          <div v-if="isAdmin">
            <label>
              <input type="checkbox" v-model="admin"> Administrador
            </label>
          </div>
          <button type="submit" :disabled="loading">
            <span v-if="!loading">CADASTRAR</span>
            <div v-else class="spinner"></div>
          </button>
          <button type="button" @click="goBack">VOLTAR</button>
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  import router from '@/router'; // Importar o router
  
  export default {
    name: 'RegisterPage',
    data() {
      return {
        name: '',
        email: '',
        password: '',
        admin: false,
        loading: false,
        errorMessage: ''
      };
    },
    computed: {
      isAdmin() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.is_admin : false;
      }
    },
    methods: {
      async register() {
        this.loading = true;
        this.errorMessage = '';
        try {
          await api.register({ name: this.name, email: this.email, password: this.password, is_admin: this.admin });
          // console.log(response.data);
          // Redirecionar para a página de login após o cadastro
          router.push({ name: 'dashboard' });
        } catch (error) {
          console.error('Erro ao fazer cadastro:', error);
          this.errorMessage = 'Erro ao cadastrar, tente novamente.';
        } finally {
          this.loading = false;
        }
      },
      goToLogin() {
        router.push({ name: 'login' });
      },
      goBack() {
        router.back();
      }
    }
  }
  </script>
  
  <style scoped>
  .container {
    display: flex;
    height: 100vh;
  }
  
  .banner {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Cor de fundo do banner */
  }
  
  .banner-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;
  }
  
  h1 {
    margin-bottom: 10px;
    color: #000;
  }
  
  .message {
    margin-bottom: 20px;
    color: #666;
  }
  
  .login-form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  input, button {
    padding: 10px;
    margin-bottom: 20px; /* Aumentado o espaço entre os elementos */
    border: 2px solid #000;
    border-radius: 15px; /* Aumentado o arredondamento */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Sombreado leve para inputs */
  }
  
  button {
    background-color: #000;
    color: white;
    cursor: pointer;
    padding: 15px 10px; /* Aumento do padding vertical para um botão mais alto */
    margin-bottom: 30px; /* Aumento do espaço após o botão */
    box-shadow: 0 6px 12px rgba(0,0,0,0.3); /* Sombreado mais pronunciado para o botão */
    transition: all 0.3s ease; /* Transição suavizada para efeitos hover */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  button[disabled] {
    cursor: not-allowed;
    background-color: #666;
  }
  
  button[type="button"] {
    background-color: #ccc;
    color: #000;
  }
  
  button[type="button"]:hover {
    background-color: #aaa;
  }
  
  .spinner {
    width: 24px;
    height: 24px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  a, p {
    color: #000;
    text-decoration: none;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
  
  .bold {
    font-weight: bold; /* Deixa o texto em negrito */
  }
  
  a:hover, p span:hover {
    text-decoration: underline;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  </style>
  