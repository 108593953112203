import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from '@/views/RegisterPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import RegisterCategoryPage from '@/views/RegisterCategoryPage.vue';
import { requireAuth, requireAdmin } from '@/middleware/auth';
import RegisterTool from '@/views/RegisterTool.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
    
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterPage, // Adicione a nova rota para a página de registro
    beforeEnter: requireAdmin
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    beforeEnter: requireAuth
  },
  {
    path: '/register-category',
    name: 'registerCategory',
    component: RegisterCategoryPage,
    beforeEnter: requireAdmin
  },
  {
    path: '/register-tool',
    name: 'registerTool',
    component: RegisterTool, // Nova rota para cadastrar ferramentas
    beforeEnter: requireAdmin
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
