<template>
    <div class="header">
      <div class="profile" @click="toggleDropdown">
        <img src="https://via.placeholder.com/80" alt="User Profile" class="profile-pic"/>
        <div v-if="showDropdown" class="dropdown">
          <ul>
            <li @click="goToRegister" v-if="isAdmin">Novo Usuario</li>
            <li @click="goToRegisterCategory" v-if="isAdmin">Nova Categoria</li>
            <li @click="goToRegisterTool" v-if="isAdmin">Nova Ferramenta</li>
            <li @click="logout">Logout</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import router from '@/router'; // Importar o router
  
  export default {
    name: 'HeaderBar',
    data() {
      return {
        showDropdown: false
      };
    },
    computed: {
      isAdmin() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.is_admin : false;
      }
    },
    methods: {
      toggleDropdown() {
        this.showDropdown = !this.showDropdown;
      },
      goToProfile() {
        this.showDropdown = false;
        router.push({ name: 'profile' });
      },
      goToRegister() {
        this.showDropdown = false;
        router.push({ name: 'register' });
      },
      goToRegisterCategory() {
        this.showDropdown = false;
        router.push({ name: 'registerCategory' });
      },
      goToRegisterTool() {
        this.showDropdown = false;
        router.push({ name: 'registerTool' });
      },
      logout() {
        this.showDropdown = false;
        localStorage.removeItem('token'); // Remove o token do localStorage
        router.push({ name: 'login' }); // Redireciona para a página de login
      }
    },
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background-color: #fff;
    position: relative;
  }
  
  .profile {
    position: relative;
    cursor: pointer;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .dropdown {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown li {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .dropdown li:hover {
    background-color: #f0f0f0;
  }
  </style>
  