// src/middleware/auth.js

export function requireAuth(to, from, next) {
    const token = localStorage.getItem('token');
    if (!token) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
  
  export function requireAdmin(to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.is_admin) {
      next();
    } else {
      next({ name: 'dashboard' });
    }
  }
  