import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { isTokenValid } from './services/auth';
import '@fortawesome/fontawesome-free/css/all.css'; // Importar Font Awesome

const app = createApp(App);

router.beforeEach((to, from, next) => {
  const isAuthenticated = isTokenValid();

  if (to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

app.use(router).mount('#app');
