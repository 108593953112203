<template>
  <div>
    <!-- Removido o botão de "hamburger" daqui -->
    <!-- Sidebar -->
    <div class="sidebar" :class="{ open: sidebarOpen }">
      <div class="logo">
        <img :src="imagemLogo" alt="Mochila do Chef Logo" />
      </div>
      <ul>
        <li v-for="category in categories" :key="category.id" :class="{ active: category.id === selectedItem }" @click="selectItem(category.id)">
          <div class="menu-item">
            <span class="icon"><i :class="'fas ' + category.icon || 'fas fa-tag'"></i></span>
            <span class="label">{{ category.name }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import imagemLogo from '@/assets/img/Mochila_do_Chef_logo_black.png';

export default {
  name: 'SidebarMenu',
  data() {
    return {
      selectedItem: 'Gestão',
      categories: [],
      imagemLogo: imagemLogo,
      sidebarOpen: false // Estado para controlar o menu
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await api.getCategories();
        this.categories = response.data;
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    },
    selectItem(categoryId) {
      this.selectedItem = categoryId;
      this.$emit('categorySelected', categoryId); // Emitindo o evento de categoria selecionada
      this.toggleSidebar(); // Fecha o sidebar ao clicar em um item
    },
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen; // Alterna entre aberto e fechado
      this.$emit('toggleSidebar', this.sidebarOpen); // Notifica o pai sobre o estado do sidebar
    }
  },
  created() {
    this.fetchCategories();
  }
};
</script>


<style scoped>
.sidebar {
  width: 250px; /* Ajuste a largura conforme necessário */
  background-color: #fff; /* Cor de fundo */
  padding: 20px;
  height: 100vh;
  box-shadow: 2px 0 10px rgba(0,0,0,0.2); /* Ajusta a sombra */
  border-right: 1px solid #ddd; /* Borda direita */
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}

.logo img {
  max-width: 100%; /* Ajusta a imagem ao tamanho do container */
  height: auto;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 20px; /* Aumentado o espaçamento entre os itens */
}

li.active .menu-item {
  background-color: #000; /* Fundo preto para o item ativo */
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); /* Sombra mais aparente */
}

li.active .icon {
  color: #f5b642; /* Cor creme para ícones ativos */
}

li.active .label {
  color: #fff; /* Cor branca para o texto do item ativo */
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none; /* Remove o sublinhado */
  color: #000; /* Cor preta para os itens de menu */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Adiciona transição para a sombra */
}

.menu-item:hover {
  background-color: #f0f0f0; /* Cor de fundo ao passar o mouse */
}

.icon {
  margin-right: 25px; /* Aumentado o espaçamento entre o ícone e o texto */
  font-size: 30px; /* Aumentado o tamanho do ícone */
  color: #888; /* Cor cinza para os ícones */
}

.label {
  vertical-align: middle;
  font-size: 20px; /* Aumentado o tamanho da fonte */
  color: #000; /* Cor preta para o texto */
}
</style>
