<template>
  <div class="container" @click="closeDropdown">
    <BannerPage />
    <div class="register-container">
      <h1>Gerenciar Categorias</h1>

      <!-- Formulário para listar categorias -->
      <form class="list-form" @submit.prevent>
        <div class="form-card">
          <div class="custom-select" @click.stop="toggleCategoryDropdown">
            <div class="selected-option">
              <i class="fa fa-list"></i> {{ selectedCategory ? selectedCategory.name : 'Selecione uma Categoria' }}
            </div>
            <div v-show="categoryDropdownOpen" class="options">
              <div 
                v-for="category in categories" 
                :key="category.id" 
                class="option" 
                @click="selectCategory(category)">
                <i :class="`fa ${category.icon}`"></i> <span class="icon-label">{{ category.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="selectedCategory">
            <input type="text" v-model="selectedCategory.name" placeholder="Nome da Categoria" required>
            <div class="custom-select" @click.stop="toggleIconDropdown">
              <div class="selected-option">
                <i :class="`fa ${selectedCategory.icon}`"></i> {{ selectedCategory.icon ? selectedCategory.icon : 'Selecione um Ícone' }}
              </div>
              <div v-show="iconDropdownOpen" class="options">
                <div 
                  v-for="icon in icons" 
                  :key="icon" 
                  class="option" 
                  @click="selectIcon(icon)">
                  <i :class="`fa ${icon}`"></i> <span class="icon-label">{{ icon }}</span>
                </div>
              </div>
            </div>
            <button type="button" @click="updateCategory" :disabled="loading">
              <span v-if="!loading">Atualizar</span>
              <div v-else class="spinner"></div>
            </button>
            <button type="button" @click="confirmDeleteCategory" class="delete-button" :disabled="loading">
              <span v-if="!loading">Excluir</span>
              <div v-else class="spinner"></div>
            </button>
          </div>
        </div>
      </form>

      <h1 v-if="!selectedCategory">Cadastrar Categoria</h1>

      <!-- Formulário para cadastrar nova categoria -->
      <form @submit.prevent="registerCategory" class="register-form" @click.stop v-if="!selectedCategory">
        <div class="form-card">
          <input type="text" v-model="name" placeholder="Nome da Categoria" required @click.stop>
          <div class="custom-select" @click.stop="toggleDropdown">
            <div class="selected-option">
              <i :class="`fa ${selectedIcon}`"></i> {{ selectedIcon ? selectedIcon : 'Selecione um Ícone' }}
            </div>
            <div v-show="dropdownOpen" class="options">
              <div 
                v-for="icon in icons" 
                :key="icon" 
                class="option" 
                @click="selectIcon(icon)">
                <i :class="`fa ${icon}`"></i> <span class="icon-label">{{ icon }}</span>
              </div>
            </div>
          </div>
          <button type="submit" :disabled="loading">
            <span v-if="!loading">Cadastrar</span>
            <div v-else class="spinner"></div>
          </button>
          <button type="button" @click="goBack" class="back-button">VOLTAR</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';
import Swal from 'sweetalert2'; // Importando SweetAlert2
import router from '@/router'; // Importar o router
import BannerPage from '@/components/BannerPage'; // Importando o componente Banner

export default {
  name: 'RegisterCategoryPage',
  components: { BannerPage },
  data() {
    return {
      name: '',
      selectedIcon: '',
      selectedCategory: null,
      loading: false,
      dropdownOpen: false,
      iconDropdownOpen: false,
      categoryDropdownOpen: false,
      icons: [
        'fa-th-large', 'fa-shopping-bag', 'fa-bell', 'fa-file-alt', 'fa-search', 'fa-user',
        'fa-home', 'fa-heart', 'fa-star', 'fa-car', 'fa-coffee', 'fa-cog',
        // Adicione mais ícones conforme necessário
      ],
      categories: []
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await api.getCategories();
        this.categories = response.data;
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    toggleIconDropdown() {
      this.iconDropdownOpen = !this.iconDropdownOpen;
    },
    toggleCategoryDropdown() {
      this.categoryDropdownOpen = !this.categoryDropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
      this.iconDropdownOpen = false;
      this.categoryDropdownOpen = false;
    },
    selectIcon(icon) {
      if (this.selectedCategory) {
        this.selectedCategory.icon = icon;
        this.iconDropdownOpen = false;
      } else {
        this.selectedIcon = icon;
        this.dropdownOpen = false;
      }
    },
    selectCategory(category) {
      this.selectedCategory = { ...category };
      this.categoryDropdownOpen = false;
    },
    async registerCategory() {
      this.loading = true;
      try {
        await api.createCategory({ name: this.name, icon: this.selectedIcon });
        this.showSuccessModal('Categoria cadastrada com sucesso.');
        this.resetForm();
        this.fetchCategories();
      } catch (error) {
        console.error('Erro ao cadastrar categoria:', error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    async updateCategory() {
      this.loading = true;
      try {
        await api.updateCategory(this.selectedCategory.id, { name: this.selectedCategory.name, icon: this.selectedCategory.icon });
        this.showSuccessModal('Categoria atualizada com sucesso.');
        this.resetForm();
        this.fetchCategories();
      } catch (error) {
        console.error('Erro ao atualizar categoria:', error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    confirmDeleteCategory() {
      Swal.fire({
        title: "Atenção!",
        text: "Esta ação irá excluir a categoria e suas ferramentas. Este processo é irreversível. Deseja continuar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCategory();
        }
      });
    },
    async deleteCategory() {
      this.loading = true;
      try {
        await api.deleteCategory(this.selectedCategory.id);
        this.showSuccessModal('Categoria excluída com sucesso.');
        this.resetForm();
        this.fetchCategories();
      } catch (error) {
        console.error('Erro ao excluir categoria:', error);
        this.showErrorModal();
      } finally {
        this.loading = false;
      }
    },
    showSuccessModal(message) {
      Swal.fire({
        title: 'Sucesso!',
        text: message,
        icon: 'success',
        confirmButtonText: 'OK'
      });
    },
    showErrorModal() {
      Swal.fire({
        title: 'Erro!',
        text: 'Houve um erro ao processar a solicitação.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    },
    goBack() {
      router.back();
    },
    resetForm() {
      this.name = '';
      this.selectedIcon = '';
      this.selectedCategory = null;
    }
  },
  created() {
    this.fetchCategories();
  }
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
}

.banner {
  flex: 1;
  background-color: #000; /* Cor de fundo preta */
}

.register-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #fff; /* Fundo branco para o formulário */
}

h1 {
  margin-bottom: 20px;
  text-align: center;
  color: #000;
}

.register-form, .list-form {
  width: 100%;
  max-width: 400px; /* Aumentar a largura máxima */
}

.form-card {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adicionar sombra externa */
  background-color: #f9f9f9;
  margin-bottom: 20px; /* Adicionar espaçamento entre os formulários */
}

input, button, .custom-select {
  width: calc(100% - 40px); /* Ajustar a largura dos campos */
  padding: 10px;
  margin: 10px 20px; /* Ajustar o espaçamento */
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.custom-select {
  position: relative;
  cursor: pointer;
}

.selected-option {
  display: flex;
  align-items: center;
}

.selected-option i {
  margin-right: 10px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f0f0f0;
}

.option i {
  margin-right: 10px;
}

button {
  background-color: #000;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

button[disabled] {
  cursor: not-allowed;
  background-color: #666;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
}

.back-button {
  background-color: #ccc;
  color: #000;
}

.back-button:hover {
  background-color: #aaa;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
}

.delete-button:hover {
  background-color: #ff1a1a;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 20px;
}
</style>
