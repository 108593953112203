import {jwtDecode} from 'jwt-decode';

export function isTokenValid() {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Tempo atual em segundos

  return decodedToken.exp > currentTime;
}
